import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';
import Home from "./views/home";
import About from "./views/about";

function App() {
  return (
      <div>
          <Router>
                <Routes>
                   <Route index element={<Home />} />
                   <Route path="/home" element={<Home /> } />
                   <Route path="/about" element={<About />} />
                </Routes>
          </Router>
      </div>
  );
}

export default App;
