import React from "react";
import "./CSS/Menu.css";

function Menu() {
    return (
        <nav>
            <ul className="menu">
                <li><a href="home">Home</a></li>
                <li><a href="about">About</a></li>
                <li><a href="Unity">Unity</a></li>
                <li><a href="Substance Painter">Substance Painter</a></li>
                <li><a href="Blender">Blender</a></li>
                <li><a href="Discord Bots">Discord Bots</a></li>
                <li><a href="Minecraft Plugins">Minecraft Plugins</a></li>
            </ul>
        </nav>
    );
}

export default Menu;